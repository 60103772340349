<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        :show-select="false"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Kullanıcılar"
            icon="mdi-account-search"
            add-route=""
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          ></rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'admin.users.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ value }">
          <rs-table-cell-email :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.phone="{ value }">
          <rs-table-cell-phone :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["roleList"]),
  },
  data() {
    return {
      options: {
        sortBy: ["name"],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Adı",
          value: "name",
          searchable: "text",
          width: "300px",
        },
        {
          text: "Rolleri",
          value: "roles",
          searchable: "multiselect",
          options: () => this.roleList,
          searchValue: "role_ids",
          itemText: "display_name",
        },
        {
          text: "Sakin Adet",
          value: "resident_count",
          searchable: "number",
          align: "end",
          width: "80px",
          notPrice: true,
        },
        {
          text: "Şirket Çalışanı Adet",
          value: "company_user_count",
          searchable: "number",
          align: "end",
          width: "80px",
          notPrice: true,
        },
        {
          text: "Telefon",
          value: "phone",
          searchable: "text",
        },
        {
          text: "E-posta",
          value: "email",
          searchable: "text",
        },
        {
          text: "TC Kimlik",
          value: "identity_number",
          searchable: "text",
        },
        {
          text: "Pasaport No",
          value: "passport_no",
          searchable: "text",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `users`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("api-super-admin/users", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "budget.definition.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleCloneClick() {
      if (this.selectedItems.length !== 1 || this.isLoading) {
        return false;
      }

      this.$refs.cloneBudgetForm.show(this.selectedItems[0]);
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`budgets/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} bütçeyi silmek istediğinizden emin misiniz?`
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
  },
};
</script>
